import useMinWidthMediaQuery from '@hooks/useMinWidthMediaQuery';
import { useGetIconsOptimized } from '@shared/hooks/useGetIconsOptimized';
import { MspLogoIcon } from '@shared/icons/msp';
import { Button } from '@shared/ui/buttons/Button';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

function useComponentState() {
	const {
		t,
		i18n: { resolvedLanguage, language },
	} = useTranslation();
	const isLargeScreen = useMinWidthMediaQuery('lg');
	const optimizedImages = useGetIconsOptimized();
	const iconDonateLg = optimizedImages.find((v) => v.name === 'icon-donate-lg');
	const iconDonateSm = optimizedImages.find((v) => v.name === 'icon-donate-sm');
	const iconDonateOptimized = isLargeScreen ? iconDonateLg : iconDonateSm;

	const buttonLink = `https://social.edopomoga.gov.ua/${resolvedLanguage || language}/cabinet/volunteer/`;

	return {
		t,
		iconDonateOptimized,
		buttonLink,
	};
}

export const DonateBanner = () => {
	const { iconDonateOptimized, t, buttonLink } = useComponentState();

	return (
		<div className='relative max-w-[1088px] mx-auto px-4 py-[39px] bg-white rounded-2xl lg:flex lg:justify-end lg:items-center lg:px-9'>
			<MspLogoIcon className='logo-banner-min-soc absolute left-0 -top-[125px] w-[124px] xs:w-[164px] h-auto sm:hidden' />
			{iconDonateOptimized && (
				<GatsbyImage
					image={getImage(iconDonateOptimized.content)}
					alt='icon man'
					className='icon-donate !absolute bottom-full left-1/2 -translate-x-1/2 h-auto w-[101px] lg:translate-x-0 lg:w-[171px] lg:left-[70px] lg:bottom-0'
				/>
			)}
			<h1
				className='text-2xl text-center lg:ml-[132px] lg:text-5xl lg:text-left lg:leading-[56px]'
				dangerouslySetInnerHTML={{ __html: t('hello-good-people') }}
			></h1>
			<div className='flex flex-col items-center mt-4 lg:items-start lg:mt-0 lg:ml-16 lg:basis-80'>
				<p className='text-center lg:text-left text-sm lg:text-base'>{t('hired-job-done')}</p>
				<Button as='link-out' size='medium' href={buttonLink} className='mt-6 lg:mt-4'>
					{t('donate')}
				</Button>
			</div>
		</div>
	);
};
