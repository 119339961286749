import { graphql, useStaticQuery } from 'gatsby';
import { useImagesOptimizedMapped } from './useImagesOptimizedMapped';

export function useGetIconsOptimized() {
	const imagesData = useStaticQuery(graphql`
		query {
			allFile(filter: { sourceInstanceName: { eq: "assets" }, extension: { in: ["png", "jpg"] }, relativeDirectory: { eq: "images" } }) {
				nodes {
					name
					image: childImageSharp {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
			}
		}
	`);

	return useImagesOptimizedMapped(imagesData);
}
