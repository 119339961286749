import React from 'react';
import { useTranslation } from 'react-i18next';

function useComponentState() {
	const { t } = useTranslation();

	return { t };
}

export const PlatformMadeBy = () => {
	const { t } = useComponentState();

	return (
		<div className='max-w-[860px] mx-auto mt-10 lg:mt-16 px-6 py-4 rounded-md bg-solitude'>
			<p>{t('platform-made-by')}</p>
		</div>
	);
};
