import { AccordionButton } from '@shared/buttons/accordion';
import React from 'react';
import { useTranslation } from 'react-i18next';

function useComponentState() {
	const { t } = useTranslation();

	return { t };
}

export const HowToHelpOnline = () => {
	const { t } = useComponentState();

	return (
		<div className='max-w-[860px] mt-10 lg:mt-16 mx-auto'>
			<AccordionButton
				size='big'
				summary={t('question-how-help-online')}
				html={t('answer-how-help-online')}
				panelClassName='prose  prose-a:text-shamrock prose-a:font-regular prose-a:no-underline prose-a:cursor-pointer hover:prose-a:underline max-w-none'
			/>
		</div>
	);
};
