import React from 'react';

import { DonateBanner } from '@widgets/donate-section/donate-banner/DonateBanner';
import { DonateSection } from '@widgets/donate-section/DonateSection';
import { DonateUkraineDemande } from '@widgets/donate-section/donate-ukraine-demande/DonateUkraineDemande';
import { PlatformMadeBy } from '@widgets/donate-section/platform-made-by/PlatformMadeBy';
import { HowToHelpOnline } from '@widgets/donate-section/how-to-help-online/HowToHelpOnline';

const MastercardEventPage = () => {
	return (
		<DonateSection>
			<DonateBanner />
			<DonateUkraineDemande />
			<PlatformMadeBy />
			<HowToHelpOnline />
		</DonateSection>
	);
};

export default MastercardEventPage;
