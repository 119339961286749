import useMinWidthMediaQuery from '@hooks/useMinWidthMediaQuery';
import { useGetIconsOptimized } from '@shared/hooks/useGetIconsOptimized';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

function useComponentState() {
	const { t } = useTranslation();
	const isLargeScreen = useMinWidthMediaQuery('lg');
	const optimizedImages = useGetIconsOptimized();
	const iconGirlLg = optimizedImages.find((v) => v.name === 'need-help-lg');
	const iconGirlSm = optimizedImages.find((v) => v.name === 'need-help-sm');
	const iconGirlOptimized = isLargeScreen ? iconGirlLg : iconGirlSm;

	return { t, iconGirlOptimized };
}

export const DonateUkraineDemande = () => {
	const { t, iconGirlOptimized } = useComponentState();

	return (
		<div className='flex max-w-[860px] mx-auto mt-10 flex-col items-center lg:flex-row lg:items-start lg:mt-16'>
			{iconGirlOptimized && (
				<GatsbyImage
					image={getImage(iconGirlOptimized.content)}
					alt='icon girl'
					className='shrink-0 w-[111px] h-auto lg:w-[127px] lg:order-2'
				/>
			)}
			<div
				dangerouslySetInnerHTML={{ __html: t('donate-ukraine-demande') }}
				className='prose prose-p:leading-6 max-w-none mt-6 px-6 lg:mr-8 lg:mt-0 lg:pr-0 lg:pl-6'
			></div>
		</div>
	);
};
